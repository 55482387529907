

import Vue from 'vue';
import BannerNotification from '@/ui/bannerNotification/index.vue';
import { FEATURE_PDE_BANNER } from '@core/constants/features';
import bannerNotificationMixin from '@/ui/bannerNotification/bannerNotificationMixin';

export const PDE_STORAGE_KEY_NAME = 'pde-available-notification-hidden';

export default Vue.extend({
  name: 'pde-available-notification',
  components: {
    BannerNotification,
  },
  mixins: [bannerNotificationMixin],
  data () {
    return {
      featureKey: FEATURE_PDE_BANNER,
      storageKey: PDE_STORAGE_KEY_NAME,
    };
  },
  computed: {
    keyName (): string {
      const personalId = this.session?.personalDomainId;
      const companyId = this.session?.businessDomainId;
      return `${this.storageKey}-${personalId}-${companyId}`;
    },
  },
});

