<copyright>
File: periodSelector.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import SubscriptionMixin from '@/modules/subscriptions/subscriptionMixIn';
import isEqual from 'lodash-es/isEqual';
import RasSubscriptionBillingPeriodsRequestV2 from '@/apiv2/rasSubscriptionBillingPeriodsRequestV2';
import RasHostsPerPeriod from '@/apiv2/rasHostsPerPeriod';
import { snakeToCamel } from '@/apiv2/apiHelpers';

export default {
  name: 'usage-period-selector',
  mixins: [SubscriptionMixin],
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      subscriptionValues: [],
      selectedSubscriptionValues: [],
      selectedParentUuid: undefined,
      billingPeriods: [],
      selectedBillingPeriodId: undefined,
      payload: {},
      isBackgroundUsageLoadingEnabled: true,
    };
  },
  created () {
    this.setInitialContract();
    if (this.selectedBillingPeriodId) this.loadHosts();
  },
  methods: {
    setInitialContract () {
      let values = this.$localStorage.get(this.storageKeyName);

      if (Array.isArray(values)) {
        values = values.filter((uuid) => this.subscriptions
          .find((subscription) => subscription.uuid === uuid)
        );
      } else if (this.subscriptionOptions.length) {
        const firstSubscription = this.subscriptionOptions[0];
        values = firstSubscription.children.map((subscription) => subscription.value);
      }

      this.subscriptionValues = values || [];
      this.selectedSubscriptionValues = this.subscriptionValues.slice();
    },
    setSelectedSubscriptionValues () {
      if (isEqual(this.selectedSubscriptionValues, this.subscriptionValues)) {
        return;
      }
      this.selectedSubscriptionValues = this.subscriptionValues.slice();
      this.isBackgroundUsageLoadingEnabled = true;
      this.loadBillingPeriods();
      this.$emit('changedSubscriptions');
    },
    resetSubscriptionValues () {
      this.subscriptionValues = this.selectedSubscriptionValues.slice();
    },
    async loadBillingPeriods () {
      if (!this.selectedContract) {
        if (this.selectedBillingPeriodId) {
          this.$emit('loadHosts', { periodId: this.selectedBillingPeriodId });
        }
        return;
      }
      this.billingPeriods = [];
      this.selectedBillingPeriodId = undefined;
      const formRequest = (url) => new RasSubscriptionBillingPeriodsRequestV2({ uuids: [this.selectedParentUuid], url });
      const callback = (result) => {
        this.billingPeriods = [].concat(this.billingPeriods, result.flat()).sort().reverse();
      };
      this.$api.authorizedAsyncPaginatedCall(formRequest, callback);
    },
    selectBillingPeriod () {
      const period = this.billingPeriods.find((period) => this.selectedBillingPeriodId === period.id) || this.billingPeriods[0];
      if (period) {
        this.selectedBillingPeriodId = period.id;
      }
    },
    loadHosts () {
      const subscriptionUuid = this.selectedContract && this.selectedContract.uuid;
      const subsetUuids = this.selectedSubscriptionValues.slice().sort();
      const payload = {
        subscriptionUuid,
        subsetUuids,
        periodId: this.selectedBillingPeriodId,
        billingPeriods: this.billingPeriods,
      };

      if (!isEqual(this.payload, payload)) {
        this.payload = payload;
        this.$emit('loadHosts', payload);
      }
    },
    async getRasHostsByChunks (chunk, subscriptionUuids) {
      const requests = chunk.map(async (period) => {
        const request = new RasHostsPerPeriod({ subscriptionUuids, periodId: period.id });
        return await this.$api.authorizedCall(request);
      });
      return await Promise.all(requests);
    },
    async loadQuarterHosts () {
      const subscriptionUuids = this.selectedSubscriptionValues.slice().sort();
      const chunk = this.billingPeriods.slice(1, 4);
      const parentUuid = this.selectedParentUuid;
      const data = await this.getRasHostsByChunks(chunk, subscriptionUuids);
      data.forEach((entity) => this.setBillingPeriodUsageData(entity, parentUuid));
      this.billingPeriods = this.billingPeriods.slice();
    },
    setBillingPeriodUsageData (entity, parentUuid) {
      if (this.selectedParentUuid !== parentUuid) {
        return;
      }
      const selectedBillingPeriod = this.billingPeriods.find((period) => period.id === entity.period_id);
      if (selectedBillingPeriod) {
        selectedBillingPeriod.hosts = entity.total_hosts;
        selectedBillingPeriod.maxUsage = entity.total_usage;
        selectedBillingPeriod.hostsUsages = snakeToCamel(entity.hosts_usages);
      }
    },
    async loadHostsUsages () {
      const currentBillingPeriod = this.billingPeriods.find((period) => period.id === this.selectedBillingPeriodId);
      if (!currentBillingPeriod.hostsUsages || !currentBillingPeriod.hostsUsages.length) {
        const subscriptionUuids = this.selectedSubscriptionValues.slice().sort();
        const request = new RasHostsPerPeriod({ subscriptionUuids, periodId: this.selectedBillingPeriodId });
        const data = await this.$api.authorizedCall(request);
        this.setBillingPeriodUsageData(data, this.selectedParentUuid);
        this.billingPeriods = this.billingPeriods.slice();
        if (this.isBackgroundUsageLoadingEnabled) {
          await this.loadQuarterHosts();
          this.isBackgroundUsageLoadingEnabled = false;
        }
      }
      this.loadHosts();
    },
  },
  computed: {
    storageKeyName () {
      return `rasSplaSelectedKeys_${this.$appData.session.businessDomainId}_${this.$appData.session.personalDomainId}`;
    },
    contracts () {
      return this.subscriptions.filter((subscription) => subscription.isPostpaid);
    },
    showContractsDropdown () {
      return this.subscriptionOptions.length > 1 || this.subscriptionOptions.some((option) => option.children.length > 0);
    },
    selectedContract () {
      const anySelectedContract = this.contracts.find((s) => s.uuid === this.selectedSubscriptionValues[0]);

      if (!anySelectedContract) {
        return null;
      } else if (anySelectedContract.isSublicense) {
        return this.contracts.find((s) => s.uuid === anySelectedContract.parentUuid) || anySelectedContract;
      } else {
        return anySelectedContract;
      }
    },
    currentSubscriptionName () {
      return this.selectedContract && this.getSubscriptionName(this.selectedContract);
    },
    subscriptionOptions () {
      const options = [];
      this.contracts.forEach((subscription) => {
        if (!subscription.isSublicense && !subscription.isDeleted) {
          const option = {
            name: this.getSubscriptionName(subscription),
            key: subscription.licKey,
            value: subscription.uuid,
          };
          const children = this.contracts
            .filter((sublicense) => sublicense.isSublicense && sublicense.parentUuid === subscription.uuid)
            .map((sublicense) => ({
              name: this.getSubscriptionName(sublicense),
              key: sublicense.licKey,
              value: sublicense.uuid,
              deleted: sublicense.isDeleted,
              parent: sublicense.parentUuid,
            }));
          const masterKey = Object.assign({}, option, { name: $t('Master Key') });

          option.children = [masterKey].concat(children);
          options.push(option);
        }
      });
      this.contracts.forEach((s) => {
        if (s.isSublicense) {
          if (options.find((option) => option.value === s.uuid)) {
            return; // sublicense is already presented in options
          }
          if (this.contracts.find((contract) => contract.uuid === s.parentUuid)) {
            return; // sublicense belongs to deleted parent
          }
          const option = {
            name: this.getSubscriptionName(s),
            key: s.licKey,
            value: s.uuid,
            deleted: s.isDeleted,
          };
          const masterKey = Object.assign({}, option, { name: $t('Master Key') });

          option.children = [masterKey];
          options.push(option);
        }
      });
      return options;
    },
    billingPeriodsOptions () {
      const note = this.$t('Farms {farms}, Peak Usage {usage}');
      return this.billingPeriods.map((period, i) => {
        // $t('{start_date} - {end_date} (current)')
        const periodString = '{start_date} - {end_date}' + (i === 0 ? ' (current)' : '');
        return {
          text: this.$t(periodString, { start_date: this.formatDateUTC(period.startedAt), end_date: this.formatDateUTC(period.endedAt) }),
          note: period.hosts ? note.replace('{farms}', period.hosts).replace('{usage}', period.maxUsage) : '',
          value: period.id,
        };
      });
    },
  },
  watch: {
    subscriptionValues (newValue, oldValue) {
      // Prevent reloading same data
      if (isEqual(newValue, oldValue)) {
        return;
      }

      if (newValue.length > oldValue.length) {
        const checkedUuid = newValue.find(uuid => oldValue.indexOf(uuid) === -1);
        const checkedContract = this.contracts.find(s => s.uuid === checkedUuid);
        const parentUuid = checkedContract.isSublicense ? checkedContract.parentUuid : checkedContract.uuid;
        this.selectedParentUuid = parentUuid;
        let i = newValue.length;
        while (i--) {
          if (this.contracts.find(
            s => (s.uuid === newValue[i]) && (s.isSublicense ? s.parentUuid : s.uuid) !== parentUuid
          )) { newValue.splice(i, 1); }
        }
      }
    },
    selectedSubscriptionValues (values) {
      this.$localStorage.set(this.storageKeyName, values);
    },
    billingPeriods (newValue) {
      if (!newValue.length) {
        this.$emit('clearHosts');
      }
      this.selectBillingPeriod();
    },
    selectedBillingPeriodId (periodId) {
      const period = this.billingPeriods.find((period) => period.id === periodId);
      if (period) {
        this.loadHostsUsages();
      }
    },
    selectedParentUuid () {
      this.loadBillingPeriods();
    },
  },
};

</script>

<template lang="pug">
  .row.margin-bottom-2x
    .col-sm-7.col-xs-12
      dropdown.spla-contract-select(
        v-if="showContractsDropdown"
        ref="ddContracts",
        :label="$t('Contracts')",
        :options="subscriptionOptions",
        v-model="subscriptionValues",
        :multiple="true",
        :highlightParent="true",
        :data-name="$name('dropdown-contracts')",
        @apply="setSelectedSubscriptionValues",
        @hide="resetSubscriptionValues"
      )
        template(slot="value", slot-scope="props")
          h2.contract-title
            .text-ellipsis
              template(v-if="selectedSubscriptionValues.length") {{ currentSubscriptionName }}
              template(v-else) {{ $t('Select SPLA contract') }}
            .dropdown-arrow
        template(slot="text", slot-scope="s")
          template(v-if="s.group")
            .text-bold(:data-name="$name('contracts-group-name')") {{ s.option.name }}
          template(v-else)
            div(:data-name="$name('contracts-option-name')")
              | {{ s.option.name }}
              span(v-if="s.option.deleted").text-danger &nbsp; (deleted)
            .text-muted.text-small(:data-name="$name('contracts-option-key')") {{ s.option.key }}
        template(slot="button") {{ $t('Apply') }}

      h2.no-margin-bottom(v-else-if="subscriptionOptions.length", v-text="subscriptionOptions[0].name")

      div(v-if="selectedContract")
        span.text-muted.text-small {{ $t('Next billing date: {date}', { date: formatDateUTC(selectedContract.nextBillingAt) }) }}
        span.margin-left
          router-link(
            :to="{ name: 'rasSubscriptionDetails', params: { id: selectedContract.uuid } }",
            :data-name="$name('link-subscription-details')"
          ) {{ $t('Details') }}
    .col-sm-5.col-xs-12.text-right.padding-top
      dropdown.block(
        v-if="selectedContract",
        :label="$t('Billing Period')",
        :options="billingPeriodsOptions",
        v-model="selectedBillingPeriodId",
        :data-name="$name('dropdown-period')"
      )
</template>

<style scoped lang="sass">

$arrow-width: $grid-step * 4
$group-padding: $grid-step * 3 / 2

.spla-contract-select
  max-width: 100%
  /deep/ .group
    .check:first-child
      padding-top: $group-padding
      padding-bottom: $group-padding
    &:nth-child(n + 2)
      box-shadow: 0 1px $silver inset

.contract-title
  margin-bottom: 0
  padding-right: $arrow-width

.dropdown-arrow
  +dropdown-arrow($arrow-width, $grid-step * 4, $dropdown-arrow-size)

</style>
