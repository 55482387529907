<copyright>
  File: step3ProblemDescription.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<style scoped lang="sass">
  .kb-content
    white-space: pre-line
</style>

<template lang="pug">

div
  .row(v-if="!showAdminList")
    .col-sm-offset-2.col-sm-8.col-xs-12
      .margin-bottom(data-qa="enter-issue-description-lbl")  {{ $t('Enter short issue description to contact Support') }}
      .margin-bottom-2x
        textbox(
          :placeholder="$t('Type your question here')",
          type="search",
          v-model.trim="searchText",
          @keyup.enter.native="throttledLoad"
          @blur="load",
          :validator="$v.searchText",
          data-qa="issue-description-textbox"
        )
          validation-errors(slot="errors", :validator="$v.searchText", :customErrors="['required']")
            template(slot="requiredError") {{ $t('Please enter a short issue description to proceed') }}

      template(v-if="articles.length")
        .margin-bottom.text-bold(data-qa="articles-might-help-lbl") {{ $t('These articles might help you:') }}
        .margin-bottom(v-for="(item, index) in articles", :data-index="index")
          a(@click="$refs.kbArticleModal.show(item)", :data-qa="item.title.toLowerCase().replace(/ /g, '-')")
            formatter(:text="item.title")
              b(:slot="mark", slot-scope="s") {{ s.text }}

      .margin-bottom(v-else-if="searched") {{ $t('Oops... Seems that we have no results here. Please rephrase or continue to contact Support Team.') }}

      .faq(v-else-if="!loading && faq.length")
        .margin-bottom(data-qa="see-popular-topics-lbl") {{ $t('See popular topics below') }}
        collapsible(
          v-for="(item, index) in faq",
          :ref="'faqItem-' + index",
          :key="index"
        )
          template(slot="title")
            span.kb-content(:data-qa="item.name.toLowerCase().replace(/ /g, '-') + '-accordion'") {{ item.name }}
          template(slot="content")
            faq-content(:text="item.content", @kbClick="onKbClick", :data-qa="item.name.toLowerCase().replace(/ /g, '-') +'-content'")

      //-- The loader is at the bottom because when we clicked on the "Show More" button, the already found content must not be disappeared.
      loader.margin-bottom-2x(:loading="loading", :text="$t('Looking for solutions')")

      template
        btn.block-xs.margin-bottom-xs(
          v-if="total > articles.length",
          color="white",
          type="button",
          @click="showMore",
          data-qa="show-more-button"
        ) {{ $t('Show More') }}
        btn.pull-right.block-xs(
          v-if="!loading",
          @click="onRequestSupportClick",
          type="submit",
          data-qa="request-support-button"
        ) {{ $t('Request Support') }}

  AdminsList(v-else)

  KbArticleModal(ref="kbArticleModal", :searchText="searchText")

  SelectAccountModal(ref="selectAccountModal", :searchText="searchText", :product="product", :supportType="supportType")
</template>

<script>

import { minLength, required } from '@core/common/validators';
import debounce from 'lodash-es/debounce';

import { scrollTo } from '@core/common/animations';
import locale from '@/plugins/locale';

import KbSearchRequest from '@/api/kb/kbSearchRequest';
import RtFaqRequest from '@/api/rt/rtFaqRequest';
import KbArticleModal from './kbArticleModal';
import SelectAccountModal from './selectAccountModal';
import FaqContent from './faqContent';
import AdminsList from './adminsList';

import {
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDPRO
} from '@core/constants/subscriptions';
import CreateTicketMixin from '@/modules/support/content/createTicketMixin';
import { GET_SUPPORT_PAGE } from '@/routes/constants';

const SEARCH_WATCH_DEBOUNCE_TIME = 1250;
const SEARCH_BY_ENTER_DEBOUNCE_TIME = 300;

export default {
  name: 'support-step3-problem-description',
  components: {
    KbArticleModal,
    SelectAccountModal,
    FaqContent,
    AdminsList,
  },

  mixins: [CreateTicketMixin],

  props: {
    product: {
      type: String,
    },
    supportType: {
      type: String,
    },
    products: {
      type: Object,
    },
    supportable: {
      type: Boolean,
    },
  },

  data () {
    return {
      loading: false,
      searchText: this.$route.query.searchText || '',
      minSearchTextLength: 2,
      oldText: '',
      articles: [],
      total: 0,
      searched: false,
      perPage: 5,
      currentPage: 1,
      faq: [],
      mark: 'b',
      locale,
      showAdminList: false,
    };
  },

  validations () {
    return {
      searchText: {
        required,
        minLength: minLength(this.minSearchTextLength),
      },
    };
  },

  created () {
    this.throttledLoad = debounce(this.load, SEARCH_BY_ENTER_DEBOUNCE_TIME);
  },

  mounted () {
    this.load();
    this.$el.querySelector('input[type=search]').focus();
  },

  methods: {

    load () {
      if (this.searchText.length >= this.minSearchTextLength) {
        this.searchArticles();
      } else {
        this.clearSearch();
        this.loadFaq();
      }
    },

    onRequestSupportClick () {
      if (this.$v.$invalid || !this.searched) {
        this.$v.$touch();
        this.$trackEvent({
          category: 'Support',
          name: 'Request Support empty click',
        });
        return;
      }

      if (!this.supportable) {
        this.showAdminList = true;
        return;
      }

      this.resetCreatedTicketCache();
      this.$trackEvent({
        category: 'Support',
        name: 'Request Support click',
        source: this.searchText,
      });

      const personalDomainId = this.$appData.session.personalDomainId;
      const isCurrentBusinessUser = this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
      const matchedBusinessDomainIds = [];
      const businessDomainsList = Object.keys(this.products.business).length > 0 ? this.products.business : [];

      for (const domainId of Object.keys(businessDomainsList)) {
        if (businessDomainsList[domainId].includes(this.product)) {
          matchedBusinessDomainIds.push(Number(domainId));
        }
      }
      if (matchedBusinessDomainIds.length > 1) {
        this.$refs.selectAccountModal.show(matchedBusinessDomainIds);
      } else if (isCurrentBusinessUser && !matchedBusinessDomainIds.length) {
        this.$appData.setUserInDomain(personalDomainId);
        this.goToGetSupport();
      } else if (matchedBusinessDomainIds.length && !matchedBusinessDomainIds.includes(this.$appData.userInDomain)) {
        this.loading = true;
        this.$api.switchBusinessDomain(matchedBusinessDomainIds[0]).then(() => {
          this.goToGetSupport();
        });
      } else {
        this.goToGetSupport();
      }
    },

    goToGetSupport () {
      this.$router.push({
        name: GET_SUPPORT_PAGE,
        params: {
          product: this.product,
          supportType: this.supportType,
          action: 'request',
        },
        query: {
          searchText: this.searchText,
        },
      });
    },

    showMore () {
      this.searchArticles(true);
    },

    loadFaq () {
      if (this.faq.length > 0) {
        return;
      }
      // Replace pdpro to pdfm in request
      const product = this.product === PRODUCT_KEY_NAME_PDPRO ? PRODUCT_KEY_NAME_PDFM : this.product;
      const request = new RtFaqRequest({ productKey: product, supportType: this.supportType, language: this.locale.rtLanguage });

      this.loading = true;

      this.$api.call(request)
        .then((data) => {
          this.faq = data;
          this.faq.forEach((section) => {
            if (section.list) {
              section.content = '';
              section.list.forEach((item) => {
                section.content += '* ' + item + '\n';
              });
            }
          });
        })
        .catch(() => {
          if (this.searchText.length >= this.minSearchTextLength) {
            this.searched = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    clearSearch () {
      this.searched = false;
      this.total = 0;
      this.articles = []; // clear previous result;
      this.currentPage = 1;
      this.oldText = '';
    },

    searchArticles (nextPage) {
      if (this.loading) {
        return;
      } else if (this.oldText !== this.searchText) {
        this.clearSearch();
      } else if (nextPage === true) {
        this.currentPage += 1;
      } else {
        return;
      }

      const request = new KbSearchRequest({
        language: this.$appData.session.language,
        productKey: this.product,
        q: this.searchText,
        perPage: this.perPage,
        page: this.currentPage,
        mark: this.mark,
      });

      this.loading = true;
      this.oldText = this.searchText;

      this.$trackEvent({
        category: 'Support',
        name: 'Description entered',
        source: this.searchText,
      });

      this.$api.callRt(request)
        .then((data) => {
          const index = this.articles.length;
          this.total = request.count;
          this.articles = this.articles.concat(request.articles.map((article) => {
            article.track = true;
            return article;
          }));

          if (this.searched) {
            setTimeout(() => {
              scrollTo({
                to: this.$el.querySelector(`[data-index="${index}"]`),
              });
            });
          }

          if (nextPage) {
            this.$trackEvent({
              category: 'Support',
              name: 'Show More click',
              source: request.articles.length,
            });
          } else {
            this.$trackEvent({
              category: 'Support',
              name: `KB shown${request.useKbSearch ? ' ps' : ''}`,
              source: request.count,
            });
          }
        })
        .finally(() => {
          this.searched = true;
          this.loading = false;
        });
    },

    onKbClick (kbId) {
      this.$refs.kbArticleModal.show({ id: kbId });
    },
  },

  watch: {
    searchText: debounce(function () {
      this.load();
    }, SEARCH_WATCH_DEBOUNCE_TIME),
  },
};

</script>
