
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'globalGroupsError',
  props: {
    groups: {
      type: Object,
      required: true,
    },
    groupUse: {
      type: String,
      required: true,
    },
    ssoConfigRoute: {
      type: Object,
      required: true,
    },
    licKey: {
      type: String,
    },
    isPrimaryKey: {
      type: Boolean,
    },
  },
  computed: {
    isAdminGroup (): boolean {
      return this.groups.firstGroup.groupUse === this.groupUse;
    },
    isUserGroup (): boolean {
      return this.groups.secondGroup.groupUse === this.groupUse;
    },
  },
});
