/**
 * File: features.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 **/

export const
  FEATURE_RAS_BETA = 'ras_beta',
  FEATURE_RAS_CLOUD_BETA = 'ras_cloud_beta',
  FEATURE_SIGN_IN_WITH_APPLE = 'sign_in_with_apple',
  FEATURE_SIGN_IN_WITH_FACEBOOK = 'sign_in_with_facebook',
  FEATURE_SIGN_IN_WITH_GOOGLE = 'sign_in_with_google',
  FEATURE_SIGN_IN_WITH_SSO = 'sign_in_with_sso',
  FEATURE_SUPPORT_PORTAL = 'support_portal',
  FEATURE_GDPR_DELETE = 'gdpr_delete',
  FEATURE_MANAGE_TRUSTED_CHECK = 'manage_trusted_check',
  FEATURE_PURCHASE_PDFC_ONLINE = 'purchase_pdfc_online',
  FEATURE_KOREAN_SUPPORT = 'korean_support',
  FEATURE_CONFIGURATION_PROFILE_PER_HOST = 'configuration_profile_per_host',
  FEATURE_CONFIGURATION_PROFILE_PER_SUBSCRIPTION = 'configuration_profile_per_subscription',
  FEATURE_PAX_AS_PERSONAL_SUPPORT = 'pax_as_personal_support',
  FEATURE_PDFC_BANNER = 'pdfc_banner',
  FEATURE_PDFC_PRODUCT = 'pdfc_product',
  FEATURE_CONFIGURATION_PROFILE_FOR_M1 = 'configuration_profile_for_m1',
  FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP = 'totp',
  FEATURE_DISABLE_EMAIL_CONDITIONAL = 'disable_email_conditional_option',
  FEATURE_RAS_EXTENDED_USAGE_NOTIFICATIONS = 'ras_extended_usage_notifications',
  FEATURE_RASA_BOT_CHAT = 'rasa_bot_chat',
  FEATURE_UNIFIED_BOT_CHAT = 'unified_chat_bot',
  FEATURE_MANAGE_CEP_OPTION = 'manage_cep_option',
  FEATURE_RAS_TECHNICAL_PREVIEW_BANNER = 'ras_technical_preview_banner',
  FEATURE_SHOW_BULA_OPTION_MODAL = 'show_bula_option_modal',
  FEATURE_SHOW_CONTINUE_WITH_SSO = 'show_continue_with_sso',
  FEATURE_CONFIRMED_ACCOUNTS = 'confirmed_accounts',
  FEATURE_ENFORCE_CONFIRMATION_FOR_BIZ_REG = 'acc_confirmation_for_business_lic_registration',
  FEATURE_STATUS_PAGE_LINK_IN_MA = 'status_page_link_in_ma_footer',
  FEATURE_DAAS_BANNER = 'daas_banner',
  FEATURE_PBI_BANNER = 'pbi_banner',
  FEATURE_PDE_BANNER = 'pde_banner';
