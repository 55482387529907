/**
 * File: externalGroups.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import Service from '@core/constants/services';

export interface ExternalUserGroup {
  'domain_uuid': string;
  'associated_groups': string[];
  'external_id': string;
  name: string;
  'parent_id': string;
  'created_at': string;
  'updated_at': string;
}

interface ExternalGroupsParams {
  limit?: number;
  offset?: number;
  groupId?: string;
}

interface ExternalGroupsResponse {
  count: number;
  next: string;
  previous: string;
  results: ExternalUserGroup[];
}

export default class ExternalGroups extends ApiRequest<ExternalGroupsParams, ExternalGroupsResponse> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    const queryString = this.params.groupId ? this.buildQueryString({ id: this.params.groupId }) : '';
    return `/${Service.Account}/api/v2/external_auths/external_domain_groups${queryString}`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }

  getGroups (): ExternalUserGroup[] {
    return this.data.results;
  }
}
